import React, { Component } from 'react';
import { Input, Row, Col } from 'reactstrap';
import moment from 'moment';
import { StatsFilter, ArticleStats, Likes } from '../../../domain';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import * as _ from 'lodash';

import { LanguageContextConsumer } from '../../LanguageContext';
import { Translate } from '../../translations';
//
// ─── ENABLE TRANSLATIONS FIXED CODE ─────────────────────────────────────────────
//
let Language = 'en';
const enableTranslations = () => {
  return (
    <LanguageContextConsumer>
      {languageContext => { Language = languageContext ? languageContext.language : 'en'; return '' }}
    </LanguageContextConsumer>
  )
}
// ────────────────────────────────────────────────────────────────────────────────

export interface Props {
  articleStats?: ArticleStats,
  onLikesClickHandler: () => void
  onSharesClickHandler: () => void
  onCommentsClickHandler: () => void
}


export class ArticleNumbers extends Component<Props> {

  toTime(val: number): string {
    const secs = Math.ceil(val);
    const minutes = Math.floor(secs / 60);
    const seconds = Math.round(secs - minutes * 60);
    return minutes + 'm' + ' ' + (seconds ? (seconds + 's') : '');
  }

  timeFormatter(cell: any, row: any) {
    return <>{this.toTime(cell && _.isNumber(cell) ? cell : 0)}</>
  }

  defaultFormatter(cell: any, row: any) {
    return <>{cell ? cell : '-'}</>;
  }

  likesFormatter(cell: any, row: any) {
    const { articleStats } = this.props;
    if (!articleStats || !articleStats.likes || _.isEmpty(articleStats.likes_uids)) {
      return <>{cell ? cell : '-'}</>;
    } else {
      return (<div style={{ cursor: 'pointer', textDecoration: 'underline' }} title="Click to see who liked this article!" onClick={() => this.props.onLikesClickHandler()}>{cell ? cell : '-'}</div>);
    }
  }
  sharesFormatter(cell: any, row: any) {
    const { articleStats } = this.props;
    if (!articleStats || !articleStats.shares || _.isEmpty(articleStats.shares_uids)) {
      return <>{cell ? cell : '-'}</>;
    } else {
      return (<div style={{ cursor: 'pointer', textDecoration: 'underline' }} title="Click to see who shared this article!" onClick={() => this.props.onSharesClickHandler()}>{cell ? cell : '-'}</div>);
    }
  }
  commentsFormatter(cell: any, row: any) {
    const { articleStats } = this.props;
    if (!articleStats || !articleStats.comments || _.isEmpty(articleStats.comments_ids)) {
      return <>{cell ? cell : '-'}</>;
    } else {
      return (<div style={{ cursor: 'pointer', textDecoration: 'underline' }} title="Click to see the comments on this article!" onClick={() => this.props.onCommentsClickHandler()}>{cell ? cell : '-'}</div>);
    }
  }

  render() {
    const { articleStats } = this.props;
    const data = articleStats ? [articleStats] : [];
    const tr = Translate(Language, 'Metrics');
    const trt = Translate(Language, 'Tables');
    return (
      <div id="ArticleNumbers">
        {enableTranslations()}
        <BootstrapTable ref="articleNumbersTable" data={data} bordered={false} hover={false} version='4' options={{}}>
          <TableHeaderColumn dataField='viewers' dataFormat={this.defaultFormatter.bind(this)} dataAlign={'center'} headerAlign={'center'} isKey><img data-tippy-content={tr("users")} src="/assets/icons/16/users.svg" /></TableHeaderColumn>
          <TableHeaderColumn dataField='views' dataFormat={this.defaultFormatter.bind(this)} dataAlign={'center'} headerAlign={'center'}><img data-tippy-content={tr("article_views")} src="/assets/icons/16/views.svg" /></TableHeaderColumn>
          <TableHeaderColumn dataField='avgTime' dataFormat={this.timeFormatter.bind(this)} dataAlign={'center'} headerAlign={'center'}><img data-tippy-content={tr("avg_reading_time")} src="/assets/icons/16/time.svg" /></TableHeaderColumn>
          <TableHeaderColumn dataField='likes' dataFormat={this.likesFormatter.bind(this)} dataAlign={'center'} headerAlign={'center'}><img data-tippy-content={tr("likes")} src="/assets/icons/16/smiles.svg" /></TableHeaderColumn>
          <TableHeaderColumn dataField='shares' dataFormat={this.sharesFormatter.bind(this)} dataAlign={'center'} headerAlign={'center'}><img data-tippy-content={tr("shares")} src="/assets/icons/16/shares.svg" /></TableHeaderColumn>
          <TableHeaderColumn dataField='comments' dataFormat={this.commentsFormatter.bind(this)} dataAlign={'center'} headerAlign={'center'}><img data-tippy-content={trt("comments")} src="/assets/icons/16/comments.svg" /></TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }
}

export default ArticleNumbers;
