import React, { Component } from 'react';
import { Input } from 'reactstrap';
import _ from 'lodash';
import { StatsFilter, Audience } from '../../../domain';

import './css/FilterBar.min.css';
import { Translations } from '../../translations';

import qs from 'query-string';
import { withRouter, RouteComponentProps } from 'react-router'
import * as ph from '../../utils/routerHelper';

import "react-datepicker3/dist/react-datepicker.css";
import { ActivePeriodSelector } from '../../components/ActivePeriodSlector/ActivePeriodSelector';
import { TippyReact } from '../../components';
import styles from './FilterBar.module.scss';
export interface Props {
  magazine: string,
  audiences: Audience[],
  globalAudience: boolean,
  statsFilter: StatsFilter,
  periodFilterHandler: (period: string) => void,
  audienceFilterHandler: (audience: number) => void,
  todayEnabled?: boolean,
}

export class FilterBar extends Component<RouteComponentProps & Props> {

  componentWillMount() {
    const params = qs.parse(this.props.location.search);
    if (!params.p || !params.a) {
      const { period, audience } = this.props.statsFilter;
      ph.setQueryParams(this.props.history, { p: period, a: audience });
    }
  }


  calcAudiences = () => {
    const { audiences, globalAudience } = this.props;
    const audiencesIds: number[] = [];
    if (globalAudience) {
      audiencesIds.push(1);
    }
    audiences.map((a) => {
      if (a.id && a.id != 1 && !a.excludeFromAnalytics) {
        audiencesIds.push(a.id);
      }
    })

    return audiencesIds;
  }
  renderInvalidAudienceFilter = () => {
    const audiencesIds = this.calcAudiences();
    const { audience } = this.props.statsFilter;
    if (!_.includes(audiencesIds, _.toNumber(audience))) {
      return (<option key={audience} value={audience}>Invalid Audience ({audience})</option>)
    }
    return null;
  }

  renderAudienceFilter() {
    const { audienceFilterHandler, statsFilter, audiences, globalAudience } = this.props;
    const tr = Translations.Translate(Translations, 'FilterBar');

    return (
      <Input type="select" value={statsFilter.audience} onChange={(e) => { audienceFilterHandler(parseInt(e.target.value)) }} className='filterSelect'>
        {globalAudience ?
          <option value="1">{tr('all_employees')}</option>
          : null
        }
        {audiences.map((a) => {
          if (_.toNumber(a.id) != 1 && !a.excludeFromAnalytics) {
            return <option key={a.id} value={a.id}>{a.label}</option>
          }
        })}
        {this.renderInvalidAudienceFilter()}
      </Input>
    )
  }
  render() {
    const { magazine, periodFilterHandler, statsFilter, todayEnabled } = this.props;
    const tr = Translations.Translate(Translations, 'FilterBar');

    return (
      <div id="FilterBar">
        {Translations.EnableTranslations(Translations)}
        <div className="row">
          <div className="col-6">
            <img src="/assets/icons/calendar.svg" style={{ marginRight: '10px' }} />
            <span className='filterLabel'>{tr('period')}</span>
            <ActivePeriodSelector {...{ magazine, periodFilterHandler, statsFilter, todayEnabled }} />
            {statsFilter.period === 'today' &&
              <TippyReact content="Keep in mind that today's analytics are ignored when other periods are selected">
                <div className={styles.todayWarningIcon}>
                  <i className={'fa fa-exclamation-triangle'}></i>
                </div>
              </TippyReact>
            }
          </div>
          <div className="col-6">
            <img src="/assets/icons/target.svg" style={{ marginRight: '10px' }} />
            <span className='filterLabel'>{tr('audience')}</span>
            {this.renderAudienceFilter()}
          </div>
        </div>
        <div className="verticalDevider"></div>
      </div>
    );
  }
}
export const FilterBarWithRouter = withRouter(FilterBar);

export default FilterBar;
